import { DeleteOutlined, EditTwoTone } from '@ant-design/icons';
import { getStatusAction } from '@app/utils/utils';

import { Pagination } from '@app/api/general.api';
import { EntityData, deleteEntity, getEntityRecords, updateEntityRecord } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { notificationController } from '@app/controllers/notificationController';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { formatNumberWithCommas, formatRupiahPrice } from '@app/utils/utils';
import { Space, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  pageSize: 10,
  current: 1,
};

export type SPKProductListTableProps = {
  spkId: string | number;
  isEditable?: boolean;
};

export const SPKProductListTable: React.FC<SPKProductListTableProps> = ({ spkId, isEditable = true }) => {
  const setLoading = useAtom(useLoading)[1];
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    loading: false,
    q: '',
  });

  const paidByMenu = (record: EntityData) => {
    const actions = [
      {
        data: 'CASH',
      },
      {
        data: 'TRANSFER',
      },
    ];

    if (actions.length == 0) return <></>;

    return (
      <Menu>
        {actions.map((action) => {
          if (action.data !== record.paid_by) {
            return (
              <MenuItem
                key={`menu-${action.data}`}
                onClick={() => {
                  //update record data
                  record.paid_by = action.data;

                  // checking if record quantity is 0
                  if (record.qty === 0) {
                    // added quantity here
                    record.qty = 1;
                    // making sure that the subtotal match
                    record.subtotal = record.price;
                  }

                  updateEntityRecord('tt_spk_product', record.id, { ...record }).then(() => {
                    notificationController.success({ message: 'Sukses Merubah Payment Info' });
                    fetch();
                  });
                }}
              >
                <Button type="text" rel="noopener noreferrer">
                  {action.data}
                </Button>
              </MenuItem>
            );
          }
        })}
      </Menu>
    );
  };

  const paymentInfoMenu = (record: EntityData) => {
    // fetch the labels from the api
    const actions = [
      {
        type: null,
        label: 'TUNAI LOKASI',
        data: 'TUNAI_LOKASI',
      },
      {
        type: 'TOKOPEDIA',
        label: 'TOKOPEDIA',
        data: 'TOKOPEDIA',
      },
      {
        type: 'SHOPEE',
        label: 'SHOPEE',
        data: 'SHOPEE',
      },
      {
        type: 'OFFICE CASH',
        label: 'OFFICE CASH',
        data: 'OFFICE_CASH',
      },
      {
        type: 'OFFICE TRANSFER',
        label: 'OFFICE TRANSFER',
        data: 'OFFICE_TRANSFER',
      },
    ];

    if (actions.length == 0) return <></>;

    return (
      <Menu>
        {actions.map((action) => {
          if (action.data !== record.payment_info) {
            return (
              <MenuItem
                key={`menu-${action.label}`}
                onClick={() => {
                  //update record data here
                  record.payment_info = action.data;
                  switch (record.payment_info) {
                    // when cash change paid by to cash
                    case 'TUNAI_LOKASI':
                      record.paid_by = 'CASH';
                      record.payment_info = 'TUNAI_LOKASI';
                      break;

                    // case when tokopedia and shopee change paid by to transfer
                    case 'TOKOPEDIA':
                      record.paid_by = 'TRANSFER';
                      break;
                    case 'SHOPEE':
                      record.paid_by = 'TRANSFER';
                      break;
                    case 'OFFICE_TRANSFER':
                      record.paid_by = 'TRANSFER';
                      break;
                    case 'OFFICE_CASH':
                      record.paid_by = 'TRANSFER';
                      break;
                  }

                  // checking if record quantity is 0
                  if (record.qty === 0) {
                    // added quantity here
                    record.qty = 1;
                    // making sure that the subtotal match
                    record.subtotal = record.price;
                  }

                  // how to call update entity record
                  updateEntityRecord('tt_spk_product', record.id, { ...record }).then(() => {
                    notificationController.success({ message: 'Sukses Merubah Payment Info' });
                    fetch();
                  });
                }}
              >
                <Button type="text" rel="noopener noreferrer">
                  {action.label}
                </Button>
              </MenuItem>
            );
          }
        })}
      </Menu>
    );
  };

  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [refetchToken, setRefetch] = useState(0);

  const fetch = useCallback(
    (query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getEntityRecords('tt_spk_product', {
        q: `spk.id==${spkId}` + (query ? `;${query}` : ''),
        sort: 'id ASC',
        inf: true,
      })
        .then((res) => {
          if (isMounted.current) {
            setTableData({
              data: res.records,
              loading: false,
              q: query || '',
            });
          }
        })
        .catch((err) => {
          console.error(`[Get SPK Product List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetch(`product.name=ilike=${filters.name}`);
    }
    fetch();
  };

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });

    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });

    deleteEntity('tt_spk_product', row?.id || '')
      .then(() => {
        notificationController.success({ message: 'Sukses menghapus produk' });
        setRefetch(refetchToken + 1);
      })
      .catch((err) => {
        console.error(`[Delete SPK Product Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus produk' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
      });
  };

  const handleEditRow = (spkId: string, rowId: string) => {
    navigate(`/transaction/${spkId}/product-spk/${rowId}/update`);
  };

  // Advanced Search
  // const setSearchText = useState('')[1];
  // const setSearchedColumn = useState('')[1];
  // const searchInput = useRef(null);

  // const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // const handleReset = (clearFilters: () => void) => {
  //   clearFilters();
  //   setSearchText('');
  // };

  // const getColumnSearchProps = (dataIndex: string): ColumnType<EntityData> => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Cari produk`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           disabled={!isEditable}
  //         >
  //           Cari
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //           disabled={!isEditable}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  //   onFilter: (value, record) => {
  //     if (!record[dataIndex]) return false;
  //     else
  //       return record[dataIndex]
  //         .toString()
  //         .toLowerCase()
  //         .includes((value as string).toLowerCase());
  //   },
  // });

  // const downloadQrCode = (record_id: string) => {
  //   getQRCode('tt_spk_product', record_id);
  // };

  const columns: ColumnsType<EntityData> = [
    {
      title: 'Paket',
      render: (text: string, record: EntityData) => {
        return <>{record.pkg ? record.pkg.name : '-'}</>;
      },
    },
    {
      title: 'Produk',
      dataIndex: 'product',
      render: (text: string, record: EntityData) => {
        return (
          // setting the direction to vertical making sure that the notes is below the name
          <Space
            direction="vertical"
            style={{
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
            }}
          >
            {record.product.name}
            {record.notes && (
              <Space
                // adding fontWeight to make sure the font is bold
                style={{
                  fontWeight: 'bold',
                }}
              >
                NB : {record.notes}
              </Space>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Qty',
      render: (text: string, record: EntityData) => {
        return <>{record.qty > 0 ? `${formatNumberWithCommas(record.qty)} ${record.product.uom.name}` : '0'}</>;
      },
    },
    {
      title: 'Harga',
      render: (text: string, record: EntityData) => {
        return <>{formatRupiahPrice(record.price)}</>;
      },
    },
    {
      title: 'Subtotal',
      render: (text: string, record: EntityData) => {
        return <>{formatRupiahPrice(record.subtotal)}</>;
      },
    },
    {
      title: 'Payment Info',
      render: (text: string, record: EntityData) => {
        if (['DRAFT', 'PROCESSED'].includes(record.spk.status)) {
          return (
            <Dropdown overlay={paymentInfoMenu(record)} trigger={['click']} key={record.id}>
              <Button onClick={(e) => e.preventDefault()}>
                {record.payment_info == 'TUNAI_LOKASI' ? 'TUNAI LOKASI' : record.payment_info} <DownOutlined />
              </Button>
            </Dropdown>
          );
        } else {
          return (
            <Dropdown overlay={paidByMenu(record)} trigger={['click']} key={record.id}>
              <Button onClick={(e) => e.preventDefault()}>
                {record.payment_info == null
                  ? record.paid_by
                  : record.payment_info == 'TUNAI_LOKASI'
                  ? 'TUNAI LOKASI'
                  : record.payment_info}
                <DownOutlined />
              </Button>
            </Dropdown>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '5%',
      render: (text: string, record: EntityData) => {
        return (
          <Space
            direction="vertical"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EditTwoTone onClick={() => handleEditRow(record.product.id, record.id)} style={{ fontSize: '20px' }} />
            <DeleteOutlined
              onClick={() => setDeleteModalData({ show: true, data: record })}
              style={{
                fontSize: '20px',
                color: 'red',
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData.data}
        pagination={false}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title="Konfirmasi"
        visible={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus {deleteModalData.data?.name}?
      </Modal>
    </>
  );
};
